import { useEffect, useRef, useState } from "react"
import TestimonialsSlider from "../../components/testimonials/testimonials-slider/TestimonialsSlider"
import "../../generic-content-page.css"
import Css from "./AboutUsPage.module.css"
import { getTestimonials } from "../../services/testimonial-service";
import PageWithSidebar from "../PageWidthSidebar/PageWithSidebar";
import ContactInformation from "../../components/contact-information/ContactInformation";
import { Helmet } from "react-helmet";

export default function AboutUsPage({ title, children, sideBarContent }) {

    const [testimonials, setTestimonials] = useState([]);
    const testiSwipperRef = useRef();

    useEffect(() => {
        getTestimonials().then(testimonials => {
            setTestimonials(testimonials);
        });
    }, [])

    useEffect(() => {
        if(testimonials.length !== 0) {
            testiSwipperRef.current.initialize();
        }
    }, [testimonials])

    const title2 = document.title;
    const description = document.querySelector("meta[name='description']").content;
    const canonical = document.querySelector("link[rel='canonical']").href;

    return (
        <>
            <Helmet>
                <title>{title2}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
            </Helmet> 
            <PageWithSidebar
                sideBarContent={(
                    <ContactInformation />
                )}
            >
                <div className="title-div">
                    <h1 className="mt-0">Sobre Inmobiliaria Restrepo Echverri</h1>
                </div>
                <div className="content">
                    <div className={"my-4 " + Css.officeSwipperWrapper}>
                        <swiper-container 
                            loop="true"
                            autoplay-delay="20000"
                            class={Css.officeSwipperContainer}>
                            <swiper-slide class={Css.officeSwipperSlide}>
                                <img src="/includes/Oficina1.jpg" className={Css.officeImg} />
                            </swiper-slide>
                            <swiper-slide class={Css.officeSwipperSlide}>
                                <img src="/includes/Oficina2.jpg" className={Css.officeImg}/>
                            </swiper-slide>
                            <swiper-slide class={Css.officeSwipperSlide}>
                                <img src="/includes/Oficina3.jpg" className={Css.officeImg}/>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                    <p>
                        Somos una firma inmobiliaria ubicada en la
                        ciudad de Manizales, reconocida como una de
                        las ciudades con mayor calidad de vida en 
                        todo el país. Con más
                        de {(new Date().getFullYear()) - 2016} años de 
                        experiencia en el apasionante mundo de la 
                        venta, arriendo y administración de 
                        propiedades, nos enorgullece ofrecer 
                        nuestros servicios como una firma honesta, 
                        profesional y confiable que se especializa 
                        en manejar propiedades de alto nivel.
                    </p>
                    <p>
                        En Restrepo Echeverri, entendemos que cada 
                        persona tiene necesidades y sueños únicos 
                        cuando se trata de bienes raíces. Es por eso 
                        que nos comprometemos a brindar un servicio 
                        personalizado y de alta calidad a cada uno 
                        de nuestros valiosos clientes. Permítanos 
                        acompañarlo en su proceso, al igual que lo 
                        hemos hecho con cientos de clientes 
                        satisfechos a lo largo de los años.
                    </p>
                    <h2>Clientes Satisfechos</h2>
                    <div className={Css.testimonialWrapper}>
                        <div className={Css.testimonialDiv}>
                            <div className={Css.testimonialIconDiv}>
                                <div className="shadow-sm">
                                    <spam class="material-icons">format_quote</spam>
                                </div>
                            </div>
                            <swiper-container 
                                class={Css.testimonialsContainer}
                                autoplay-delay="5000"
                                loop="true"
                                pagination="true"
                                pagination-clickable="true"
                                // navigation="true"
                                pagination-clicable
                                init="false"
                                ref={testiSwipperRef}
                            >
                                {testimonials.map(testimonial => {
                                    return (
                                        <swiper-slide class={Css.testimonialsSlide}>
                                            <div className={Css.testimonialQuote}>
                                                <p>
                                                    {testimonial.msg}
                                                </p>
                                            </div>
                                            <div className={Css.testimonialAuthor}>
                                                <p>{testimonial.nombre}</p>
                                            </div>
                                            <div className={Css.testimonialDesc}>
                                                <p>{testimonial.tipo}</p>
                                            </div>
                                        </swiper-slide>
                                    )
                                })}
                            </swiper-container>
                        </div>
                    </div>
                </div>
            </PageWithSidebar>
        </>
    )
}