import Css from "./Footer.module.css";
import Cert from "../../assets/certificado_fedelonjas.jpg";
import TagManager from "react-gtm-module";
import { RETagManager } from "../../utils/gtm-utils";

export default function Footer() {
    const year = new Date().getFullYear();
    const copyYear = year - 2023 > 0 ? "2023 - " + year : year;

    return (
        <footer>
            {/* WhatsApp Buble */}
            <a 
                href="https://wa.me/573228547342"
                target="_blank"
                rel="noopener noreferrer"
                className={"shadow " + Css.whatsAppBubleWrapper}
                onClick={() => RETagManager.sendWhatsAppAttemptEvent()}>
                <img className="img-fluid" src="/includes/Whatsapp_Icon.png"/>
            </a>
            {/* End WhatsApp Buble */}
            <div className={Css.footerWrapper + " mt-4 pt-4 shadow"}>
                <div className={Css.upperWrapper}>
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <p className="mb-2"><b>Inmuebles</b></p>
                                <div className={"mb-3 " + Css.linksDiv}>
                                    <p className="mb-0"><a href="/propiedades/casa/venta/">Casas en venta</a></p>
                                    <p className="mb-0"><a href="/propiedades/casa/arriendo/">Casas en arriendo</a></p>
                                    <p className="mb-0"><a href="/propiedades/apartamento/venta/">Apartamentos en venta</a></p>
                                    <p className="mb-0"><a href="/propiedades/apartamento/arriendo/">Apartamentos en arriendo</a></p>
                                    <p className="mb-0"><a href="/propiedades/oficina/venta/">Oficinas en venta</a></p>
                                    <p><a href="/propiedades/oficina/arriendo/">Oficinas en arriendo</a></p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <p className="mb-2"><b>Contacto</b></p>
                                <div className={Css.infoDiv}>
                                    <p className="mb-0">WhatsApp / Teléfonos:</p>
                                    <a className="mb-0"
                                    href="https://wa.me/573228547342"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                                    >
                                        +57 322 854 7342
                                    </a>
                                    <a className="mb-2" href='https://wa.me/573143507094
' 
                                        target='_blank' 
                                        rel='noopener noreferrer'
                                        onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                                    >
                                        +57 314 3507094
                                    </a>
                                </div>
                                <div className={Css.infoDiv}>
                                    <p className="mb-0">Dirección:</p>
                                    <p className="mb-3">
                                        Avenida Alberto Mendoza<br />
                                        Cra. 23 A No. 74-71<br />
                                        Oficina 504<br />
                                        Edificio Andi<br />
                                        Manizales<br />
                                        {/* Oficina 504<br />
                                        <a href="https://goo.gl/maps/ADKTTLk7pv41b3De6" target="_blank" rel="noopener noreferrer">Edificio Andi</a>
                                        Manizales, Colombia */}
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <p className="mb-2"><b>Redes sociales</b></p>
                                <div className={Css.infoDiv}>
                                    <p className="mb-0">Instagram:</p>
                                    <a className="mb-2"
                                        style={{overflowWrap: "break-word"}}
                                        href="https://www.instagram.com/inmobiliariarestrepoecheverri/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => RETagManager.sendInstagramLinkClickEvent()}
                                    >
                                        @inmobiliariarestrepoecheverri
                                    </a>
                                </div>
                                <div className={Css.infoDiv}>
                                    <p className="mb-0">Facebook:</p>
                                    <a className="mb-3"
                                        href="https://www.facebook.com/inmobiliariarestrepoecheverri"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => RETagManager.sendFacebookClickEvent()}
                                    >
                                        /inmobiliariarestrepoecheverri
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 pt-md-2 pt-lg-0">
                                <p className="mb-2"><b>Matrícula inmobiliaria:</b></p>
                                <div className={Css.infoDiv}>
                                    <p style={{color: "var(--re-gray)", paddingBottom: "1em"}}>SGM-MAOMW-2023-15</p>
                                </div>
                                <div className="d-flex flex-column justify-content-center mt-4">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="mb-2">
                                            <a href="https://www.fedelonjas.org.co/" target="_blank" rel="noopener noreferrer">
                                                <img 
                                                    src="/includes/logo_fedelonjas.png"
                                                    alt="Certificado Fedelonjas"
                                                    className={Css.certImg + " img-fluid"}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="mt-2">
                                            <a href="https://lonjacaldas.com/" target="_blank" rel="noopener noreferrer">
                                                <img 
                                                    src="/includes/logo_lonja_caldas.png"
                                                    alt="Certificado Fedelonjas"
                                                    className={Css.certImg + " img-fluid"}
                                                />
                                            </a>
                                        </div>
                                        <div class="mt-5">
                                            <a 
                                                href="https://customers.ecollect.co/html/10340892/ecollect10340892.html"
                                                target="_blank" rel="noopener noreferrer"
                                                class="d-flex justify-content-center"
                                            >
                                                <img alt="Boton de pago PSE" class={Css.pseButton + " img-fluid"} src="/includes/Boton_PSE.png" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={Css.middleWrapper}>
                    <hr />
                    <div className="container">
                        <div className="d-flex justify-content-end">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="me-2">
                                    <a href="https://www.fedelonjas.org.co/" target="_blank" rel="noopener noreferrer">
                                        <img 
                                            src="/includes/logo_fedelonjas.png"
                                            alt="Certificado Fedelonjas"
                                            className={Css.certImg + " img-fluid"}
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <div className="ms-2">
                                    <a href="https://lonjacaldas.com/" target="_blank" rel="noopener noreferrer">
                                        <img 
                                            src="/includes/logo_lonja_caldas.png"
                                            alt="Certificado Fedelonjas"
                                            className={Css.certImg + " img-fluid"}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={Css.lowerWrapper}>
                    <hr />
                    <div className="container d-flex flex-column flex-lg-row justify-content-between">
                        <div className={Css.legalInfo}>
                            <p>&copy; {copyYear} | Inmobiliaria Restrepo Echeverri</p>
                            <p>
                                Todos los derechos reservados
                                <br className="d-inline d-md-none"/>
                                <span className="d-none d-md-inline"> | </span>* Algunas imágenes son tomadas de
                                <span> </span><a href="http://www.freepik.com/" target="_blank" rel="noopener noreferrer">Freepik</a>
                            </p>
                        </div>
                        <div className={Css.developerInfo}>
                            <p>Desarrollado por: Juan Esteban Arboleda</p>
                            <p>
                                Contácto desarrollador: <a href="mailto:juanesteban.arboledagc@gmail.com" onClick={() => RETagManager.sendDevsEmailAtemptEvent()}>
                                    juanesteban.arboledagc@gmail.com
                                </a>
                            </p>
                            <a href="/politica_privacidad_RE.pdf" className="text-size-small d-block mt-4">
                                Política de privacidad y tratamiento de datos personales
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}