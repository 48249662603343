import { Helmet } from "react-helmet";
import ContactInformation from "../../components/contact-information/ContactInformation";
import Css from "./ContactPage.module.css";
import PageWithSidebar from "../PageWidthSidebar/PageWithSidebar";
import ContactForm from "../../components/contact-form/ContactForm";

export default function ContactPage() {
    const title = document.title;
    const description = document.querySelector("meta[name='description']").content;
    const canonical = document.querySelector("link[rel='canonical']").href;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
            </Helmet>
            <PageWithSidebar sideBarContent={(
                <ContactInformation />
            )}>
                <h1 className="mt-0">Contáctenos</h1>
                <ContactForm title={"Formulatio de contacto"} />
            </PageWithSidebar>
        </>
    )
}