import './App.css';
import Header from './components/header/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import Footer from './components/footer/Footer';
import PropertySearchPage from './pages/PropertySearch/PropertySearchPage';
import { addQueryParams2Url, getCurrentQueryParams, getCurrentUrl } from './utils/url-utils';
import Error404 from './components/errors/Error404';
import { createContext, useEffect } from 'react';
import ContactPage from './pages/ContactPage/ContactPage';
import PropertyDetailPage from './pages/PropertyDetail/PropertyDetailPage';
import { RETagManager } from './utils/gtm-utils';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import PrivacyNoticePage from './pages/PrivacyNoticePage/PrivacyNoticePage';

/**
 * @type {React.Context<Object.<string, any>>}
 */
export const CacheContext = createContext();

export default function App() {
    const cache = {};

    const location = useLocation();

    useEffect(() => {
        RETagManager.initialize();
    }, []);

    useEffect(() => {
        RETagManager.sendPageViewEvent({
            page: location.pathname + location.search
        });
    }, [location]);

    let url = getCurrentUrl();
    if(url.slice(-1) !== "/") {
        url = url + "/";
        const qp = getCurrentQueryParams();
        if(Object.keys(qp).length > 0) url = addQueryParams2Url(url, qp);
        window.location.href = url;
        console.log("Redirect performed to add '/' to the end of URL");
        return;
    }

    return (
        <>
            <CacheContext.Provider value={cache}>
                {/* <BrowserRouter> */}
                    <Header/>
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path='/propiedades/*' element={<PropPageRouter />} />
                        <Route path='/contactenos/' element={<ContactPage />} />
                        <Route path='/sobre-nosotros/' element={<AboutUsPage />} />
                        {/* <Route path='/aviso-privacidad/' element={<PrivacyNoticePage />} /> */}
                        <Route path='*' element={<Error404 />} />
                    </Routes>
                    <Footer />
                {/* </BrowserRouter> */}
            </CacheContext.Provider>
        </>
    );
}

function PropPageRouter() {
    const location = useLocation(); //Nesesary so component re-renders when the URL changes
    const pathName = location.pathname;
    const propCode = pathName.replace("/propiedades/", "");
    if(/^[0-9]+\/?$/.test(propCode)) {
        return <PropertyDetailPage />
    }

    return <div><PropertySearchPage /></div>
}