import { RETagManager } from "../../../utils/gtm-utils";
import Css from "./ThanksComponent.module.css";

export default function ThanksComponent(props) {
    return (
        <div className={"d-flex flex-column " +  Css.sentWrapper}>
            <div className='d-flex justify-content-center'>
                <img className='img-fluid' width="128" height="128" src='/re-icons/message_sent_icon.png'/>
            </div>
            <div className={Css.infoDiv}>
                <p className={Css.thanks}>Gracias, 
                    <span className={Css.name}> {props.firstName.split(" ")[0]}</span></p>
            </div>
            <div className={Css.infoDiv}>
                <p>Su mensaje fue entregado con éxito.
                    Pronto nuestro equipo se pondrá en
                    contacto con usted.</p>
            </div>
            <hr className={Css.sep} />
            <div className={Css.infoDiv}>
                <p>También puede contactarnos por teléfono o WhatsApp</p>
                <p className={Css.phoneTitle}>WhatsApp</p>
                <a className={Css.phone} href='https://wa.me/573228547342' 
                    target='_blank' 
                    rel='noopener noreferrer'
                    onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                >
                        +57 322 854 7342
                </a>
                <a className={Css.phone} href='https://wa.me/573143507094
' 
                    target='_blank' 
                    rel='noopener noreferrer'
                    onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                >
                        +57 314 3507094
                </a>
                <p className={Css.phoneTitle}>Teléfonos</p>
                <a 
                    className={Css.phone}
                    href='tel:+573228547342'
                    onClick={() => RETagManager.sendCallAtemptEvent()}
                >
                    +57 322 854 7342
                </a>
                <a 
                    className={Css.phone}
                    href='tel:+573143507094'
                    onClick={() => RETagManager.sendCallAtemptEvent()}
                >
                    +57 314 3507094
                </a>
            </div>
        </div>
    )
}