import PropertySearchForm from "../../components/property-search/property-search-form/PropertySearchForm";
import TestimonialsSlider from "../../components/testimonials/testimonials-slider/TestimonialsSlider";
import Css from "./MainPage.module.css";
import ContactInformation from "../../components/contact-information/ContactInformation";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import PropertyList from "../../components/property-list/PropertyList";
import useWindowDimensions from "../../hooks/WindowDimensionsHook";
import { BUSINESS_TYPE_SALE } from "../../components/property-search/business-type-select/BusinessTypeSelect";


function MainPage(props) {

    const { width } = useWindowDimensions();
    let take = 4;
    if(width >= 992) {
        take = 3;
    }
    if(width >= 1200) {
        take = 4;
    }

    const title = document.title;
    const description = document.querySelector("meta[name='description']").content;
    const canonical = document.querySelector("link[rel='canonical']").href;

    return (
        <div className="mb-5">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
            </Helmet>
            <h1 className="hidden-seo-tag" aria-hidden="true">Inmobiliarias en Manizales</h1>
            <div className={Css.propertySearchWrapper}>
                <div className="w-100 h-100 d-flex justify-content-center">
                    <div className="container">
                        <div className="h-100 w-100 d-flex flex-column justify-content-center">
                            <PropertySearchForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className={Css.inmuWrapper}>
                <div className={Css.inmuTitleDiv + " container"}>
                    <a href="/propiedades/casa/venta/manizales/" className="no-dec-link">
                        <h2 className={Css.inmuTitle + " " + Css.first}>Casas en Venta en Manizales</h2>
                    </a>
                </div>
                <PropertyList filters={{propType: {value: "1"}, businessType: {value: BUSINESS_TYPE_SALE}}} take={take}/>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <a 
                                className="re-bg-blue d-flex flex-row
                                    justify-content-center p-2 no-dec-link
                                    re-color-white"
                                href="/propiedades/casa/venta/manizales/"
                            >
                                <div>Ver todas</div>
                                <img src="/re-icons/chevron_right_white.svg" />
                            </a>
                        </div>
                    </div>
                    {/* <hr /> */}
                </div>
            </div>
            <div className={Css.inmuWrapper}>
                <div className={Css.inmuTitleDiv + " container"}>
                    <a href="/propiedades/apartamento/venta/manizales/" className="no-dec-link">
                        <h2 className={Css.inmuTitle}>Apartamentos en Venta en Manizales</h2>
                    </a>
                </div>
                <PropertyList filters={{propType: {value: "2"}, businessType: {value: BUSINESS_TYPE_SALE}}} take={take}/>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <a 
                                className="re-bg-blue d-flex flex-row
                                    justify-content-center p-2 no-dec-link
                                    re-color-white"
                                href="/propiedades/apartamento/venta/manizales/"
                            >
                                <div>Ver todos</div>
                                <img src="/re-icons/chevron_right_white.svg" />
                            </a>
                        </div>
                    </div>
                    {/* <hr /> */}
                </div>
            </div>
            <div className={Css.inmuWrapper}>
                <div className={Css.inmuTitleDiv + " container"}>
                    <a href="/propiedades/finca/venta/" className="no-dec-link">
                        <h2 className={Css.inmuTitle}>Fincas en Venta cerca a Manizales</h2>
                    </a>
                </div>
                <PropertyList filters={{propType: {value: "7"}, businessType: {value: BUSINESS_TYPE_SALE}}} take={take}/>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <a 
                                className="re-bg-blue d-flex flex-row
                                    justify-content-center p-2 no-dec-link
                                    re-color-white"
                                href="/propiedades/finca/venta/"
                            >
                                <div>Ver todas</div>
                                <img src="/re-icons/chevron_right_white.svg" />
                            </a>
                        </div>
                    </div>
                    {/* <hr /> */}
                </div>
            </div>
            <div className={Css.inmuWrapper}>
                <div className={Css.inmuTitleDiv + " container"}>
                    <a href="/propiedades/lote-terreno/venta/" className="no-dec-link">
                        <h2 className={Css.inmuTitle}>Lotes en Venta cerca a Manizales</h2>
                    </a>
                </div>
                <PropertyList filters={{propType: {value: "5"}, businessType: {value: BUSINESS_TYPE_SALE}}} take={take}/>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <a 
                                className="re-bg-blue d-flex flex-row
                                    justify-content-center p-2 no-dec-link
                                    re-color-white"
                                href="/propiedades/lote-terreno/venta/"
                            >
                                <div>Ver todos</div>
                                <img src="/re-icons/chevron_right_white.svg" alt="Flecha a la derecha" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainPage;