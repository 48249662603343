import { useEffect, useState } from "react";
import Css from "./PropertyDetail.module.css";
import { getCurrentUrl } from "../../utils/url-utils";
import { useNavigate } from "react-router-dom";
import { getPropertyById } from "../../services/property-service";
import { register } from "swiper/element";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSink, faTreeCity, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import PropImageSwiper from "./image-swiper/PropImageSwiper";
import PropDetailList from "./detail-list/PropDetailList";
import PropDetailSummary from "./detail-summary/PropDetailSummary";
import PropFeatureList from "./feature-list/PropFeatureList";
import PropMap from "./map/PropMap";
import { registerPropertyVisit } from "../../services/visit-service";
import LoadingPropertyDetail from "./LoadingPropertyDetail";
import PropDescription from "./description/PropDescription";
import { Helmet } from "react-helmet";
import Error from "../errors/Error";
import { RENT_TYPES } from "../../services/rent-type-service";
import PropertyPrice from "./prop-price/PropertyPrice";

register();

export default function PropertyDetail(props) {
    const property = props.property;
    const loading = !Boolean(props.property);
    let hideSwiper;
    if(property) {
        hideSwiper = (property.propImages.length === 0) && (!property.video || property.video === "");
    }

    if(loading) {
        return <LoadingPropertyDetail />
    }

    const canonical = document.querySelector("link[rel='canonical']").href;

    return (
        <div className={Css.wrapper}>
            <Helmet>
                <title>{property.title}</title>
                <meta name="description" content={property.observations} />
                <link rel="canonical" href={canonical} />
            </Helmet>
            <div className="">
                <div className={"row " + Css.titlePriceDiv}>
                    <div className="col-12">
                        <h1 className={Css.title}><strong>{property.title}</strong></h1>
                    </div>
                    {props.hidePrice ? (
                        null
                    ) : (
                        <div className="col-12">
                            <PropertyPrice property={property} />
                        </div>
                    )}
                    
                </div>
                <div className={Css.swiperWrapper + (hideSwiper ? " d-none" : "")}>
                    <PropImageSwiper imgs={property.propImages} video={property.video} />
                </div>
                <div className={Css.summaryWrapper}>
                    <PropDetailSummary property={property} />
                </div>
                <div className={Css.descriptionWrapper}>
                    <PropDescription property={property} />
                </div>
                <div className={Css.detailsWrapper}>
                    <PropDetailList property={property} />
                </div>
                <div className={Css.featureWrapper}>
                    <PropFeatureList features={property.features ? property.features.internal : null}
                        title={"Características internas"} />
                </div>
                <div className={Css.featureWrapper}>
                    <PropFeatureList features={property.features ? property.features.external : null} 
                        title={"Características externas"} />
                </div>
                <div className={Css.mapWrapper}>
                    <PropMap property={property} />
                </div>
            </div>
        </div>
    )
}

function ErrorComponent(props) {
    let errMsg = "Hubo un error cuando intentamos obtener la información de la propiedad.";
    if(props.code && props.code === 404) {
        errMsg = "No encontramos la propiedad a la que navegaste."
    }
    return (
        <Error title="Error | Inmobiliaria Restrepo Echeverri">
            <div className={"container " + Css.errorDiv}>
                <div><FontAwesomeIcon icon={faTriangleExclamation} /></div>
                <p className="text-size-3">Oops. {errMsg}</p>
                <p className="text-size-2">
                    Ésto puede ocurrir porque ingresaste un código inexistente,
                    porque la propiedad ya no está disponible o
                    porque hay algún problema en nuestro servidor.
                </p>
                <p className="text-size-2">Intenta <a href="/propiedades/">volver al buscador de propiedades</a></p>
            </div>
        </Error>
    )
}